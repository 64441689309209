<script setup lang="ts">
import CategoryIcon from "~/components/Categories/CategoryIcon.vue"
import type { CategoryType } from "~/types"

const props = defineProps<{
  label: string,
  icon: CategoryType,
  active?: boolean
}>()

const internalActive = ref(false)

const emit = defineEmits(['click'])

watch(() => props.active, (value) => {
  nextTick(() => internalActive.value = value)
}, { immediate: true })
</script>

<template>
  <div v-tooltip="{
    value: label
  }" class="flex flex-col md:flex-row items-center gap-8 md:gap-2">
    <button
      class="group w-full md:w-10 md:h-10 flex justify-start items-center cursor-pointer hover:scale-110 focus-within:scale-110 transition ease-in-out duration-300 focus:outline-none"
      @click="$emit('click')">
      <div class="category relative flex items-center gap-4 w-[180px] mx-auto"
        :class="{ ['category--active']: internalActive }">
        <CategoryIcon class="hidden md:block" id="desktop" :active="internalActive" :icon="icon" :label="label"
          gradient />
        <CategoryIcon class="block md:hidden" id="mobile" :active="internalActive" :icon="icon" :label="label"
          :gradient="!internalActive" />
        <span class="flex md:hidden text-md font-bold" :class="{
          'text-primary': internalActive,
          'text-white hover:text-primary/90': !internalActive,
        }">
          {{ label }}
        </span>
      </div>
    </button>
  </div>
</template>