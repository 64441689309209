<script setup lang="ts">
import { storeToRefs } from "pinia"
import { ref } from "vue"
import { Category, PageUrl } from "~/types";
import Bars3Icon from '@heroicons/vue/20/solid/Bars3Icon'
import FunnelIcon from '@heroicons/vue/20/solid/FunnelIcon'
import XMarkIcon from '@heroicons/vue/20/solid/XMarkIcon'
import HeaderCategories from "~/components/Layout/HeaderCategories.vue"
import IconButton from '~/components/UI/IconButton.vue'
import Filters from '~/components/Filter/Filters.vue'

const pageUrl = PageUrl

const props = defineProps<{
  alternative?: boolean
}>()

const { rateList } = storeToRefs(useRateStore())
const router = useRouter()

const navigationMenu = ref()
const filterMenu = ref()

const isListPage = computed(() =>
  router.currentRoute.value.name === `category-${Category.ENERGY}` ||
  router.currentRoute.value.name === `category-${Category.INSURANCE}` ||
  router.currentRoute.value.name === `category-${Category.TELCO}`
);

const bgGradient = computed(() =>
  props.alternative ? 'from-[#EE7D22] to-[#F5A840]' : 'from-[#E55092] via-[#E76A7D] via-[#E97A70] to-[#EA806C]'
)

const onNavigateToRoot = () => {
  router.push('/')
}
</script>

<template>
  <nav class="fixed left-0 top-0 z-50 h-20 w-full bg-gradient-to-r md:h-28" :class="bgGradient">
    <div class="mx-auto flex h-full w-auto items-center justify-between gap-4 px-4 xl:container md:gap-8 xl:px-0">
      <div class="relative z-10 flex w-auto cursor-pointer items-center justify-center font-semibold normal-case"
        @click="onNavigateToRoot">
        <img class="flex sm:hidden" src="/images/logo-mini.png" width="48" alt="risparmia tu logo"
          title="risparmia tu logo" />
        <img class="hidden sm:flex" src="/images/logo.png" width="200" alt="risparmia tu logo"
          title="risparmia tu logo" />
      </div>
      <div class="flex gap-4 md:hidden">
        <!-- FILTER MENU -->
        <FunnelIcon v-if="isListPage"
          class="!flex justify-center items-center h-10 w-10 p-1 text-2xl text-white rounded-full hover:bg-white hover:text-[#EA806C] cursor-pointer"
          @click="filterMenu = true" />
        <Sidebar v-model:visible="filterMenu" position="bottom" :block-scroll="true" :show-close-icon="false">
          <div class="flex flex-col gap-8 scrollable-container p-8 max-h-[80vh] overflow-scroll">
            <div class="flex justify-between items-center gap-4">
              <span class="text-sm text-slate-400 font-bold uppercase">
                Filtri
              </span>
              <IconButton variant="transparent" size="small" @click="filterMenu = false">
                <XMarkIcon class="" @click="filterMenu = false" />
              </IconButton>
            </div>
            <Filters :options="rateList" @submit="filterMenu = false" />
          </div>
        </Sidebar>
        <!-- NAVIGATION MENU -->
        <Bars3Icon
          class="!flex justify-center items-center h-10 w-10 p-1 text-2xl text-white rounded-full hover:bg-white hover:text-[#EA806C] cursor-pointer"
          @click="navigationMenu = true" />
        <Sidebar v-model:visible="navigationMenu" position="top" :block-scroll="true" :show-close-icon="false">
          <div class="w-full flex flex-col gap-4 pt-8 pb-6 px-4 h-auto bg-gradient-to-r" :class="bgGradient">
            <HeaderCategories @change="navigationMenu = false" />
            <ul class="flex flex-col gap-4 font-bold">
              <li class="h-10 flex justify-center items-center">
                <NuxtLink :to="`/${pageUrl.BLOG}`" title="Blog" prefetch>
                  <div class=" md:hover:text-primary/80 text-gray-100" activeClass="text-accent">
                    Blog
                  </div>
                </NuxtLink>
              </li>
              <li class="h-10 flex justify-center items-center">
                <NuxtLink :to="`/${pageUrl.ABOUT_US}`" title="Chi Siamo" prefetch>
                  <div class=" md:hover:text-primary/80 block text-gray-100">
                    Chi Siamo
                  </div>
                </NuxtLink>
              </li>
              <li class="h-10 flex justify-center items-center">
                <NuxtLink :to="`/${pageUrl.HOW_IT_WORKS}`" title="Come funziona">
                  <div class="md:hover:text-primary/80 block text-gray-100">
                    Come Funziona
                  </div>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </Sidebar>
      </div>
      <div id="navbar-sticky"
        class="z-10 absolute hidden w-full flex-1 items-center justify-between md:relative md:order-1 md:flex md:w-auto">
        <HeaderCategories />
        <ul class="flex gap-4 divide-x font-bold">
          <li class="flex-0 flex items-center justify-center">
            <NuxtLink :to="`/${pageUrl.BLOG}`" title="Blog">
              <div class="md:hover:text-primary/80 block text-gray-100">
                Blog
              </div>
            </NuxtLink>
          </li>
          <li class="flex-0 flex items-center justify-center pl-4">
            <NuxtLink :to="`/${pageUrl.ABOUT_US}`" title="Chi Siamo">
              <div class="md:hover:text-primary/80 block text-gray-100">
                Chi Siamo
              </div>
            </NuxtLink>
          </li>
          <li class="flex-0 flex items-center justify-center pl-4">
            <NuxtLink :to="`/${pageUrl.HOW_IT_WORKS}`" title="Come funziona">
              <div class="md:hover:text-primary/80 block text-gray-100">
                Come Funziona
              </div>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  @media (min-width: 768px) {

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 112px;
      width: 112px;
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 9;
      opacity: 0.9;
    }

    &:before {
      left: -48px;
      background-image: url('/images/triangle_left.svg');
    }

    &:after {
      right: -48px;
      background-image: url('/images/triangle_right.svg');
    }
  }
}
</style>
