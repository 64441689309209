
<script setup lang="ts">
import type { EnergyRateType, GenericRateType, InsuranceRateType, TelcoRateType } from "~/types"
import FiltersInsurance from "./FiltersInsurance.vue";
import FiltersTelco from "./FiltersTelco.vue";
import FiltersEnergy from "./FiltersEnergy.vue";

defineProps<{
  options: (GenericRateType)[],
}>()

const { isEnergy, isTelco, isInsurance } = storeToRefs(useCategoryStore())
</script>

<template>
  <FiltersEnergy v-if="isEnergy" :options="(options as EnergyRateType[])" />
  <FiltersInsurance v-else-if="isInsurance" :options="(options as InsuranceRateType[])" />
  <FiltersTelco v-else-if="isTelco" :options="(options as TelcoRateType[])" />
</template>